import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, createTheme, SxProps, ThemeOptions, ThemeProvider } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import React, { CSSProperties } from 'react';
import { fontFamilies } from '../../utils';
import Tooltip from '../dataDisplay/Tooltip';
import { CFRAMuiIconWrapper } from '../Icon';
import { tooltipThemeV2 } from '../themes/theme';

type SelectionProps = {
    selectItems?: Array<string | any | { menuItem: JSX.Element }>;
    currentSelection?: string;
    handleSelectionChange: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void;
    tooltipTitle?: string;
    themeOptions?: ThemeOptions;
    externalTooltipOpen?: boolean;
    paperSxProps?: SxProps;
    iconComponent?: React.ElementType<any>;
    placeholder?: string;
    showOnlyIcon?: boolean;
    sx?: SxProps;
    tooltipContainerStyles?: CSSProperties;
};

export const fontStyle = { fontFamily: fontFamilies.GraphikRegular, fontSize: '14px' };

const StyledSelect = styled(Select)(({ theme }) => ({
    ...fontStyle,
    borderRadius: '10px', // Should be here to override, not possible via theme
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    ...fontStyle,
    color: '#3C3C3C',
    display: 'flex',
    height: '48px',
    alignItems: 'center',
    padding: '0px 10px !important',
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '14px',
    '&:focused': {
        backgroundColor: 'transparent !important',
    },
}));

export const iconTheme = createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: '#5A5A5A',
                    fontSize: '20px',
                },
            },
        },
    },
});

const selectThemeOptions: ThemeOptions = {
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: '#3078B5',
                    verticalAlign: 'top',
                    height: '48px',
                    padding: '0px 10px 0px 6px',
                    border: '1px solid #E4E4E4'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    height: 'auto'
                }
            }
        }
    }
}

//mouse handler is an optional event to handle open/close behavior for a surrounding tooltip [controlled tooltip].
export function CFRARoundedSelect({
    selectItems = [],
    currentSelection,
    handleSelectionChange,
    themeOptions,
    tooltipTitle,
    externalTooltipOpen,
    paperSxProps,
    iconComponent,
    placeholder,
    showOnlyIcon,
    sx,
    tooltipContainerStyles,
}: SelectionProps) {
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const handleTooltip = (state: boolean) => setTooltipOpen(state);

    const _theme = createTheme(selectThemeOptions, tooltipThemeV2, themeOptions || {});

    return (
        <ThemeProvider theme={_theme}>
            <Tooltip
                title={tooltipTitle}
                open={externalTooltipOpen && tooltipOpen}
                tooltipContainerStyles={tooltipContainerStyles}>
                <StyledSelect
                    IconComponent={
                        iconComponent ||
                        function () {
                            return <CFRAMuiIconWrapper MuiIcon={KeyboardArrowDown} theme={iconTheme} />;
                        }
                    }
                    variant='outlined'
                    value={currentSelection}
                    onChange={handleSelectionChange}
                    onMouseEnter={() => handleTooltip(true)}
                    onMouseLeave={() => handleTooltip(false)}
                    onOpen={() => handleTooltip(false)}
                    SelectDisplayProps={{
                        style: {
                            color: '#3C3C3C',
                        },
                    }}
                    displayEmpty={Boolean(placeholder)}
                    renderValue={
                        showOnlyIcon
                            ? () => <Box sx={{ width: '100%', height: '100%', color: 'transparent' }}>-</Box>
                            : placeholder
                            ? (value) => (typeof value === 'string' && value !== '' ? <>{value}</> : <>{placeholder}</>)
                            : undefined
                    }
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                filter: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))',
                                boxShadow: 'none',
                                borderRadius: '10px',
                                padding: '10px 0 10px',
                                marginTop: '10px',
                                ...paperSxProps,
                            },
                        },
                    }}
                    sx={sx}>
                    {selectItems.map(
                        (item) =>
                            item.menuItem || (
                                <StyledMenuItem value={item} key={item}>
                                    {item}
                                </StyledMenuItem>
                            ),
                    )}
                </StyledSelect>
            </Tooltip>
        </ThemeProvider>
    );
}
